$medium: 850px;
$large: 1200px;

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  // justify-content: center;

  padding: 1rem;

  @media screen and (min-width: $medium) {
    padding: 72px;
  }

  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    margin-bottom: 3rem;

    @media screen and (min-width: $medium) {
      flex-direction: row;
      align-items: center;
    }

    .profile {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      @media screen and (min-width: $medium) {
        margin-bottom: 0;
      }

      img {
        width: 80px;
        height: 80px;
        border-radius: 40px;

        margin-right: 24px;
      }

      .info {
        color: var(--primary);

        h2 {
          font-size: 2rem;
          font-weight: 700;

          @media screen and (min-width: $medium) {
            font-size: 2.25rem;
          }
        }

        p {
          font-size: 1rem;
          font-weight: 300;

          @media screen and (min-width: $medium) {
            font-size: 1.125rem;
          }
        }
      }
    }

    .socialMediaContainer {
      display: flex;
      align-self: stretch;
      // justify-content: space-between;

      @media screen and (min-width: $medium) {
        align-self: unset;
        justify-content: center;
      }

      a {
        display: flex;
        align-items: flex-end;
        margin-left: 24px;

        &:first-child {
          margin-left: 0;
        }

        @media screen and (min-width: $medium) {
          margin-left: 24px;
        }

        font-size: 1.5rem;
        font-weight: 700;
        text-decoration: none;
        color: var(--primary-text);

        p {
          margin-left: 8px;
          line-height: 1.3rem;
        }

        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }

  .content {
    display: flex;
    // flex: 1;
    // align-items: center;
    // justify-content: space-between;

    .me {
      display: flex;
      flex: 1;
      justify-content: space-between;
      flex-direction: column;

      @media screen and (min-width: $large) {
        flex-direction: row;
      }

      .about {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (min-width: $large) {
          max-width: 45%;
          margin-right: 8.25rem;
        }

        & > p {
          font-size: 0.9rem;
          color: var(--primary-text);
          font-weight: 400;
          margin-bottom: 32px;
          text-align: justify;

          @media screen and (min-width: $large) {
            text-align: left;
            font-size: 1.1rem;
          }

          a {
            text-decoration: none;
            color: var(--primary-100);
          }
        }

        .technologies {
          display: flex;
          flex-direction: column;

          margin-bottom: 2rem;

          @media screen and (min-width: $large) {
            margin-bottom: 0;
          }

          h4 {
            font-size: 1.125rem;
            color: var(--primary);

            margin-bottom: 16px;
          }

          .logos {
            display: flex;
            flex-wrap: wrap;

            a {
              text-decoration: none;

              img {
                margin-right: 16px;
                margin-bottom: 16px;

                @media screen and (min-width: $large) {
                  margin-bottom: 0;
                }
              }

              &:hover {
                filter: brightness(0.8);
              }
            }
          }
        }
      }

      .projects {
        display: flex;
        flex-direction: column;

        opacity: 0.3;

        & > :first-child {
          margin-bottom: 2rem;
        }

        & > :last-child {
          margin-bottom: 2rem;

          @media screen and (min-width: $large) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
