:root {
  --background: #0f1318;
  --project-button-background: #13181f;
  --primary: #00b25d;
  --primary-100: #00703a;
  --primary-text: #efefef;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--background);
  background-image: url("../assets/images/dots.png");
  background-size: contain;
  font-family: "Poppins", sans-serif;
}
